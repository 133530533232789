.unavailable-page-grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.unavailable-page-content {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white to blend with dark background */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Slightly darker shadow for depth */
  max-width: 400px;
  width: 100%;
  color: white; /* Ensure the text is white for contrast */
}

.unavailable-page-header {
  font-size: 2rem; /* Larger for emphasis */
  color: #ffcc00; /* Yellow for emphasis against the dark background */
  margin-bottom: 1rem;
}

.unavailable-page-message {
  font-size: 1.4rem; /* Slightly larger for better visibility */
  color: #ffffff; /* Bright white for contrast against dark background */
  margin: 0;
  line-height: 1.5;
}
