div > button.MuiButton-root.bar-animation,
form > button.MuiButton-root.bar-animation {
  backface-visibility: hidden;
  border: 0px;
  background: linear-gradient(90deg, #d303c9 0%, #e3022f 50%, #d303c9 100%);
  background-size: 200% 200%;
  animation-direction: normal;
  animation-delay: 1s;
  animation: moving-gradient 2s linear infinite;
}

.gradient-button {
  width: 100%;
  max-width: 400px;
  height: 40px;
  margin-top: 4px;
  font-weight: 400;
  border-radius: 11px;
}

.gradient-button:focus {
  outline: 1px solid white;
}

.hidden {
  display: none;
  margin-top: 48px !important;
}

.soft-hidden {
  display: none !important;
}
