div.full-screen-height-landing {
  min-height: calc(calc(var(--vh, 1vh) * 100) - 100px);
  height: 100%;
  position: relative;
  margin-top: 1rem;
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
div.full-screen-height-landing-small {
  min-height: calc(calc(var(--vh, 1vh) * 100) - 200px);
  height: 100%;
  position: relative;
  margin-top: 1rem;
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.center-box-div {
  width: 100%;
}
.skeleton-masked-input {
  background-color: #352446;
  margin-top: 21px;
  width: 100%;
  max-width: 400px;
  border-radius: 11px;
}
div.center-box-div > h3.title-location-name {
  margin-top: 0px;
  margin-bottom: calc(0.5em - 2px);
}
.date-picker {
  border-radius: 10px 0 0 10px;
  border-right: 0px;
  width: 50%;
}
.time-picker {
  border-radius: 0 10px 10px 0;
  width: 50%;
}
.final-amount-disclaimer {
  font-size: 12px;
  margin-top: 4px;
}
div.landing-page-center-box {
  margin-top: 16px;
}
div.landing-page-center-box > div:nth-of-type(1) {
  min-height: 300px;
  height: 100%;
  margin-bottom: 0px !important;
}
p.time-picker-helper-text,
h6.time-picker-helper-text,
div.time-picker-helper-text {
  font-size: 12px;
  margin-top: 4px;
}
span.MuiSkeleton-root.title-skeleton {
  height: 60px;
  width: 70%;
  margin: auto;
  margin-bottom: 0.35em;
}
span.MuiSkeleton-root.event-price-skeleton {
  height: 30px;
  width: 70%;
  margin: auto;
}
.pricing-skeleton-desc {
  font-size: 1rem;
  width: 120px;
}
.pricing-skeleton-rate {
  font-size: 1rem;
  width: 50px;
}
h6.event-style-accent {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 4px;
}
h6.event-style-accent-bottom {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.event-text-body {
  font-size: 16px;
  font-weight: 300;
}
.event-header-div {
  margin-top: 20px;
  margin-bottom: 20px;
}
.event-price-div {
  margin-top: 10px;
  margin-bottom: 20px;
}
div.phone-number-input > div > input {
  -webkit-box-shadow: #0d0533 0px 0px 0px 100px inset !important;
  box-shadow: #0d0533 0px 0px 0px 100px inset !important;
}
div.grid-override {
  padding-left: 0px !important;
  padding-top: 0px !important;
}
h6.how-long-margin {
  margin-bottom: 25px;
}
h6.time-picker-warning-text {
  font-size: 12px;
  margin-top: 4px;
  color: #ff9696;
}
h6.amount-preview-text {
  cursor: pointer;
  font-size: 15px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
h6.amount-preview-text:focus-visible {
  outline: none;
}
h6.amount-preview-text span {
  font-weight: 600;
  margin-left: 4px;
}
h6.amount-preview-text svg.MuiSvgIcon-root {
  height: 18px;
  width: 18px;
  margin-left: 5px;
}
.price-summary td.MuiTableCell-root {
  border-bottom: thin solid #0000000f;
}
.price-summary .total td.MuiTableCell-root {
  font-weight: 600;
  background-color: #0000000a;
}
.price-summary .promo-code td.MuiTableCell-root {
  font-weight: 300;
  color: #6d6d6d;
}
.price-summary .promo-code span.icon {
  display: flex;
}
.price-summary .promo-code span.icon span {
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  border-radius: 4px;
  color: #000;
  font-weight: 600;
}
.price-summary .promo-code span.icon span svg.MuiSvgIcon-root {
  color: #6d6d6d;
  margin-right: 5px;
  height: 0.8em;
  width: 0.8em;
}
.price-summary .promo-code span.message {
  font-size: 11px;
}
.price-summary tr.subtotal {
  background-color: #0000000a;
}
.price-summary tr.subtotal td {
  font-weight: 500;
}
.price-summary tr.tax td.MuiTableCell-root {
  color: #8e8e8e;
}
.price-summary td.MuiTableCell-root {
  font-weight: 300;
}
.dropdown-select {
  width: 100%;
  text-align-last: center;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.251);
  border-radius: 11px;
}
.dropdown-select:focus {
  outline: 2px solid #75b5ee;
}
.unavailable-page-grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.unavailable-page-content {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  /* Semi-transparent white to blend with dark background */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  /* Slightly darker shadow for depth */
  max-width: 400px;
  width: 100%;
  color: white;
  /* Ensure the text is white for contrast */
}
.unavailable-page-header {
  font-size: 2rem;
  /* Larger for emphasis */
  color: #ffcc00;
  /* Yellow for emphasis against the dark background */
  margin-bottom: 1rem;
}
.unavailable-page-message {
  font-size: 1.4rem;
  /* Slightly larger for better visibility */
  color: #ffffff;
  /* Bright white for contrast against dark background */
  margin: 0;
  line-height: 1.5;
}
.success-page-main-row {
  min-height: calc(100vh - 160px);
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.success-page-main-row button,
.success-page-main-row div {
  width: 100%;
  max-width: 400px;
}
span.button-skeleton {
  font-size: 3.4rem;
  width: 100%;
  border-radius: 11px;
}
.feedback-button {
  width: 100%;
  height: 40px;
  margin-bottom: 24px;
}
.feedback-button svg.MuiSvgIcon-root {
  margin-left: 4px;
  height: 0.9em;
  width: 0.9em;
}
button.extend-stay-button {
  margin-top: 1rem;
}
button.submit-extend-stay,
button.submit-extend-stay:hover {
  margin-top: 8px;
  margin-bottom: 12px;
}
.next-end-time {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.next-end-time svg {
  margin-left: 5px;
}
p.time-error {
  color: #ff270a;
}
h6.error-message {
  font-size: 12px;
  margin-top: 5px;
  color: #ff0000;
}
button.cancel-button {
  border-color: #ffffff3b;
}
button.cancel-buttonbutton.MuiButton-root.gradient-button,
button.cancel-button.MuiButton-root[disabled],
button.cancel-button.MuiButton-root[disabled]:hover {
  background: unset;
}
button.mt-10 {
  margin-top: 12px;
}
.extend-stay-area {
  z-index: 9999999999;
  background: #0d0533;
  padding-bottom: 1rem;
  position: relative;
  margin-bottom: 6rem;
}
div.max-date-error {
  margin-top: 1rem;
  text-align: left;
  background: #0d0533;
  z-index: 9999999999;
  position: relative;
  margin-bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #ffffff3b;
}
div.max-date-error div.MuiAlert-icon {
  padding: 0;
}
div.max-date-error div.MuiAlert-icon svg.MuiSvgIcon-root {
  height: 2.5rem;
  width: 2.5rem;
}
div.max-date-error div.MuiAlertTitle-root {
  font-weight: 600;
}
button.mt-15 {
  margin-top: 1rem;
}
div.extend-stay-summary {
  gap: 24px;
  flex-direction: column;
  display: inline-flex;
  max-width: 350px;
}
@media screen and (max-height: 600px) {
  div.extend-stay-summary {
    gap: 12px;
  }
}
@media screen and (min-height: 600px) and (max-height: 700px) {
  div.extend-stay-summary {
    gap: 12px;
  }
}
@media screen and (min-height: 700px) {
  div.extend-stay-summary {
    gap: 15px;
  }
}
@media screen and (min-height: 780px) {
  div.extend-stay-summary {
    gap: 20px;
  }
}
@media screen and (min-height: 800px) {
  div.extend-stay-summary {
    gap: 24px;
  }
}
.license-edit-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
.license-edit {
  display: flex;
}
.license-edit button.MuiButton-root {
  margin-left: 6px;
  background-color: #125bfb;
  padding: 8px;
}
div.payment-error svg.MuiSvgIcon-root.error-icon {
  height: 6rem;
  width: 6rem;
  color: #ff270a;
}
div.payment-error h1 {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
div.payment-error p {
  margin-bottom: 3rem;
}
div.license-edit-dialog div.MuiDialog-paper {
  background-color: #0d0533;
  background-image: unset;
}
div.license-edit-dialog p {
  margin-bottom: 1rem;
}
div.license-edit-dialog div.action {
  text-align: right;
  margin-top: 1rem;
}
div.license-edit-dialog div.action button.gradient-button {
  max-width: 150px;
}
.small-dimmed-text {
  margin-top: 10px;
  text-align: center;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}
div.grid-space {
  gap: 24px;
  flex-direction: column;
  display: inline-flex;
  max-width: 350px;
}
.post-payment-instructions {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  margin: 5px 0 16px;
  white-space: pre-line;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
}
div > button.MuiButton-root.bar-animation,
form > button.MuiButton-root.bar-animation {
  backface-visibility: hidden;
  border: 0px;
  background: linear-gradient(90deg, #d303c9 0%, #e3022f 50%, #d303c9 100%);
  background-size: 200% 200%;
  animation-direction: normal;
  animation-delay: 1s;
  animation: moving-gradient 2s linear infinite;
}
.gradient-button {
  width: 100%;
  max-width: 400px;
  height: 40px;
  margin-top: 4px;
  font-weight: 400;
  border-radius: 11px;
}
.gradient-button:focus {
  outline: 1px solid white;
}
.hidden {
  display: none;
  margin-top: 48px !important;
}
.soft-hidden {
  display: none !important;
}
.datetime-picker-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 99.6%;
  margin: 4px 0;
  border: 1px solid rgba(255, 255, 255, 0.251);
  border-radius: 11px;
}
.datetime-picker-container:focus {
  outline: 1px solid 75b5ee;
}
.datetime-picker-container .datetime-input {
  flex: 1;
  padding: 8px;
  margin: 0;
  margin-right: 10px;
  border: none;
  min-height: 20px;
  color-scheme: dark;
  font-size: 100%;
  font-weight: 300;
  vertical-align: baseline;
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}
.datetime-picker-container .datetime-input:focus {
  outline: none;
}
.datetime-picker-container .datetime-input::-webkit-calendar-picker-indicator {
  margin: 0 -7px;
}
.datetime-picker-container .datetime-input::-webkit-datetime-edit-year-field {
  color: #ffffff;
}
.datetime-picker-container .datetime-input::-moz-datetime-edit {
  color: #ffffff;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
/* If we are doing CSS in JS, this should be the only style we use / need. */
html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: 300;
  vertical-align: baseline;
  background-color: #0d0533;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  min-height: 100%;
}
p {
  margin-block-end: 0;
  margin-block-start: 0;
}
a {
  color: #008fcb;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.height-100 {
  height: 100%;
}
.font-weight-600 {
  font-weight: 600;
}
.outline:focus {
  outline: 1px solid white;
}
.outline-alternate:focus {
  outline: 1px solid #d303c9;
}
.no-border {
  border: 0 !important;
}
.margin-auto {
  margin: auto;
}
.text-center {
  text-align: center;
}
.dropdown-active {
  display: inline-block;
}
.display-none {
  display: none;
}
.root-col {
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
}
.root-col p {
  z-index: 9;
}
.primary-text-success {
  margin-top: 0px;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
img.cloudpark-logo-status {
  width: 90px;
  margin: auto;
  z-index: 4;
}
button.MuiButton-root.gradient-button,
.MuiButton-root[disabled],
.MuiButton-root[disabled]:hover {
  border: 0px;
  font-weight: 600;
  background: linear-gradient(90deg, #e3022f 0%, #d303c9 100%);
}
.content-slide-out {
  animation: content-slide-out 1s forwards;
}
.content-slide-in {
  animation: content-slide-in 1s forwards;
}
@keyframes moving-gradient {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: -100% 0;
  }
}
@keyframes content-slide-in {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes content-slide-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(200%);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(0.7);
  }
}
.error-boundary {
  width: calc(100% - 12px);
  font-family: Roboto, Arial;
  border: 2px solid #dd3227;
  border-radius: 2px;
  padding: 12px;
  margin: 6px;
  gap: 8px;
  margin-top: 2rem;
}
.error-boundary.border-0 {
  border: 0;
}
div.error-boundary-header {
  text-align: center;
  margin-top: 6px;
}
.error-boundary-header-text {
  font-size: 18px;
  margin: 0;
}
svg.error-boundary-icon {
  font-size: 80px;
  margin: 6px 0;
}
.error-boundary-footer {
  text-align: center;
  margin-bottom: 6px;
}
.error-boundary-footer-text {
  font-size: 16px;
  margin: 0;
}
#header-menu ul.MuiList-root {
  padding: 0px;
}
div.MuiOutlinedInput-root {
  border-radius: 11px;
}
header.MuiPaper-root {
  box-shadow: unset;
}
.header .MuiContainer-root {
  padding: 5px 4px 0px 10px;
}
.header .MuiTypography-root {
  flex-grow: 1;
}
.header .MuiSvgIcon-root {
  font-size: 45px;
  margin-top: -1rem;
  cursor: pointer;
}
#header-menu .menu-item {
  background: #ffffff;
  color: #000000;
}
#header-menu .menu-item svg {
  margin-left: 0.5rem;
}
.text-body2 {
  margin-bottom: 1rem !important;
  font-size: 16px !important;
  font-weight: 300 !important;
}
.text-h3 {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.text-h4 {
  font-size: 20px !important;
  font-weight: 600 !important;
}
.text-h6 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
div.fallback-spinner {
  color: #fff;
  background-color: #0d0533;
}
span.MuiDivider-wrapper {
  font-size: 12px;
}
h6.submit-error {
  font-size: 12px;
  margin-top: 10px;
  color: #ff0000;
}
body > iframe {
  pointer-events: none;
}
td.MuiTableCell-root {
  border-bottom: thin solid #ffffff1f;
}
.error-message {
  color: #ff0000;
}
div#notistack-snackbar {
  max-width: calc(100% - 43px);
}
div.notistack-MuiContent button.MuiButtonBase-root {
  color: #fff;
}
div.notistack-SnackbarContainer {
  z-index: 9999999999;
}
.denced-table td.MuiTableCell-root {
  padding: 10px 16px;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
div.full-screen-header {
  min-height: calc(calc(var(--vh, 1vh) * 100) - 175px);
}
