div.full-screen-height-landing {
  min-height: calc(calc(var(--vh, 1vh) * 100) - 100px);
  height: 100%;
  position: relative;
  margin-top: 1rem;
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

div.full-screen-height-landing-small {
  min-height: calc(calc(var(--vh, 1vh) * 100) - 200px);
  height: 100%;
  position: relative;
  margin-top: 1rem;
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.center-box-div {
  width: 100%;
}

.skeleton-masked-input {
  background-color: #352446;
  margin-top: 21px;
  width: 100%;
  max-width: 400px;
  border-radius: 11px;
}

div.center-box-div > h3.title-location-name {
  margin-top: 0px;
  margin-bottom: calc(0.5em - 2px);
}

.date-picker {
  border-radius: 10px 0 0 10px;
  border-right: 0px;
  width: 50%;
}

.time-picker {
  border-radius: 0 10px 10px 0;
  width: 50%;
}

.final-amount-disclaimer {
  font-size: 12px;
  margin-top: 4px;
}

div.landing-page-center-box {
  margin-top: 16px;
}

// Set 268 because that is the minimum height of the more complex scheduling box, and we want the Pricing to not move when animating.
div.landing-page-center-box > div:nth-of-type(1) {
  min-height: 300px;
  height: 100%;
  margin-bottom: 0px !important;
}

p.time-picker-helper-text,
h6.time-picker-helper-text,
div.time-picker-helper-text {
  font-size: 12px;
  margin-top: 4px;
}

span.MuiSkeleton-root.title-skeleton {
  height: 60px;
  width: 70%;
  margin: auto;
  margin-bottom: 0.35em;
}

span.MuiSkeleton-root.event-price-skeleton {
  height: 30px;
  width: 70%;
  margin: auto;
}

.pricing-skeleton-desc {
  font-size: 1rem;
  width: 120px;
}

.pricing-skeleton-rate {
  font-size: 1rem;
  width: 50px;
}

h6.event-style-accent {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 4px;
}

h6.event-style-accent-bottom {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.event-text-body {
  font-size: 16px;
  font-weight: 300;
}

.event-header-div {
  margin-top: 20px;
  margin-bottom: 20px;
}

.event-price-div {
  margin-top: 10px;
  margin-bottom: 20px;
}

div.phone-number-input > div > input {
  -webkit-box-shadow: #0d0533 0px 0px 0px 100px inset !important;
  box-shadow: #0d0533 0px 0px 0px 100px inset !important;
}

div.grid-override {
  padding-left: 0px !important;
  padding-top: 0px !important;
}

h6.how-long-margin {
  margin-bottom: 25px;
}

h6.time-picker-warning-text {
  font-size: 12px;
  margin-top: 4px;
  color: #ff9696;
}

h6.amount-preview-text {
  cursor: pointer;
  font-size: 15px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    outline: none;
  }

  span {
    font-weight: 600;
    margin-left: 4px;
  }

  svg.MuiSvgIcon-root {
    height: 18px;
    width: 18px;
    margin-left: 5px;
  }
}

.price-summary {
  td.MuiTableCell-root {
    border-bottom: thin solid #0000000f;
  }

  .total {
    td.MuiTableCell-root {
      font-weight: 600;
      background-color: #0000000a;
    }
  }

  .promo-code {
    td.MuiTableCell-root {
      font-weight: 300;
      color: #6d6d6d;
    }

    span.icon {
      display: flex;

      span {
        background: #ececec;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 6px;
        border-radius: 4px;
        color: #000;
        font-weight: 600;

        svg.MuiSvgIcon-root {
          color: #6d6d6d;
          margin-right: 5px;
          height: 0.8em;
          width: 0.8em;
        }
      }
    }

    span.message {
      font-size: 11px;
    }
  }

  tr.subtotal {
    background-color: #0000000a;

    td {
      font-weight: 500;
    }
  }

  tr.tax td.MuiTableCell-root {
    color: #8e8e8e;
  }

  td.MuiTableCell-root {
    font-weight: 300;
  }
}

.dropdown-select {
  width: 100%;
  text-align-last: center;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.251);
  border-radius: 11px;

  &:focus {
    outline: 2px solid #75b5ee;
  }
}
