.datetime-picker-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 99.6%;
  margin: 4px 0;
  border: 1px solid rgba(255, 255, 255, 0.251);
  border-radius: 11px;

  &:focus {
    outline: 1px solid 75b5ee;
  }

  .datetime-input {
    flex: 1;
    padding: 8px;
    margin: 0;
    margin-right: 10px;
    border: none;
    min-height: 20px;

    color-scheme: dark;
    font-size: 100%;
    font-weight: 300;
    vertical-align: baseline;
    background-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;

    &:focus {
      outline: none;
    }
  }
  .datetime-input::-webkit-calendar-picker-indicator {
    margin: 0 -7px;
  }

  .datetime-input::-webkit-datetime-edit-year-field {
    color: #ffffff;
  }
  .datetime-input::-moz-datetime-edit {
    color: #ffffff;
  }
}
